@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: var(--font-satoshi), sans-serif;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.font12_16 {
  font-size: 0.75rem;
  font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
}

.font12_18 {
  font-size: 0.75rem;
  font-size: clamp(0.75rem, 0.675rem + 0.375vw, 1.125rem);
}

.font12_24 {
  font-size: 0.75rem;
  font-size: clamp(0.75rem, 0.6rem + 0.75vw, 1.5rem);
}

.font14_18 {
  font-size: 0.875rem;
  font-size: clamp(0.875rem, 0.825rem + 0.25vw, 1.125rem);
}

.font14_20 {
  font-size: 0.875rem;
  font-size: clamp(0.875rem, 0.8rem + 0.375vw, 1.25rem);
}

.font14_32 {
  font-size: 0.875rem;
  font-size: clamp(0.875rem, 0.65rem + 1.125vw, 2rem);
}

.font18_28 {
  font-size: 1.125rem;
  font-size: clamp(1.125rem, 1rem + 0.625vw, 1.75rem);
}

.font20_30 {
  font-size: 1.25rem;
  font-size: clamp(1.25rem, 1.125rem + 0.625vw, 1.875rem);
}

.font20_32 {
  font-size: 1.25rem;
  font-size: clamp(1.25rem, 1.1rem + 0.75vw, 2rem);
}

.font20_42 {
  font-size: 1.25rem;
  font-size: clamp(1.25rem, 0.975rem + 1.375vw, 2.625rem);
}

.font22_28 {
  font-size: 1.375rem;
  font-size: clamp(1.375rem, 1.3rem + 0.375vw, 1.75rem);
}

.font22_32 {
  font-size: 1.375rem;
  font-size: clamp(1.375rem, 1.25rem + 0.625vw, 2rem);
}

.font30_36 {
  font-size: 1.875rem;
  font-size: clamp(1.875rem, 1.8rem + 0.375vw, 2.25rem);
}

.section-gap {
  @apply bg-primary100 rounded-[30px] p-6 sm:p-10 md:p-20 shadow-lg backdrop-blur-sm relative overflow-hidden mt-[100px];
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: flex;
  animation: marquee 20s linear infinite;
  will-change: transform;
}

@media (max-width: 768px) {
  .animate-marquee {
    display: flex;
    animation: marquee 10s linear infinite;
    /* Adjust timing for smoothness */
    will-change: transform;
  }
}

/* Add these styles for better mobile navbar behavior */
.sticky-nav {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
}

/* Prevent body scroll when menu is open */
body.menu-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

/* Add this to your global CSS file */
.slick-dots {
  bottom: 16px;
  z-index: 10;
}

.slick-dots li button:before {
  color: white;
  opacity: 0.8;
  font-size: 8px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}

/* Custom arrow styles */
.slick-prev,
.slick-next {
  z-index: 10;
}

/* Hover effect for images */
.slick-slide {
  transition: transform 0.3s ease;
}

.slick-slide:hover {
  transform: scale(1.02);
}

@layer base {
  .prose {
    @apply max-w-none;
  }

  .prose img {
    @apply w-full max-w-[680px] mx-auto my-6 md:my-8;
  }

  .prose p {
    @apply mb-4 md:mb-6;
  }

  .prose h2 {
    @apply mt-8 md:mt-12 mb-4 md:mb-6;
  }

  /* Container width for different screen sizes */
  .content-container {
    @apply max-w-[680px] mx-auto px-4;
  }
}

@layer components {
  .text-primary {
    @apply text-blue-600;
  }

  .bg-primary {
    @apply bg-blue-600;
  }

  .ring-primary {
    @apply ring-blue-600;
  }

  .text-error500 {
    @apply text-red-500;
  }

  .bg-error50 {
    @apply bg-red-50;
  }
}
